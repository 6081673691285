import { path } from 'ramda';

import { FILTER } from 'api/consts';

import {
  FEATURE_NAME,
  SEEN_ANIMATION,
  DATA,
  CERTIFICATE_KIND,
  TOPOLOGY,
  POPULATION_ID,
  ELDERLY_ID,
  DEMENTIA_ID,
  DOCTOR_ID,
} from './consts';

export const getSeenAnimation = path([FEATURE_NAME, SEEN_ANIMATION]);
export const getFilter = path([FEATURE_NAME, FILTER]);
export const getCertificateKind = path([FEATURE_NAME, CERTIFICATE_KIND]);
export const getTopology = path([FEATURE_NAME, TOPOLOGY]);
export const getPublicPopulation = path([FEATURE_NAME, DATA, POPULATION_ID]);
export const getPublicElderly = path([FEATURE_NAME, DATA, ELDERLY_ID]);
export const getPublicDementia = path([FEATURE_NAME, DATA, DEMENTIA_ID]);
export const getPublicDoctorDensity = path([FEATURE_NAME, DATA, DOCTOR_ID]);
