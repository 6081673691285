import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

import { palette, fontStyles } from 'theme';

export const Container = styled(motion.div).attrs(({ $isOpened, $defaultOpened }) => ({
  animate: {
    flex: $isOpened || $defaultOpened ? '2 1 auto' : '0 0 auto',
    height: $isOpened || $defaultOpened ? 'auto' : 0,
    marginBottom: $isOpened || $defaultOpened ? '5vh' : 0,
  },
  transition: { duration: $isOpened && !$defaultOpened ? 2 : 0 },
  initial: {
    flex: '0 0 auto',
    height: 0,
    marginBottom: 0,
  },
  layoutScroll: true,
}))`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
`;

export const Wrapper = styled.div`
  flex: 1 1 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 2px;
  min-height: 100px;
  background-color: ${palette.black};
  padding: 2px;
`;

export const Item = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ $color }) => $color};
  text-decoration: none;
  transition: background-color 0.3s;
  &:active {
    background-color: ${palette.white};
    &:after {
      background-color: ${({ $color }) => $color};
    }
  }
  @media (hover) {
    &:hover {
      background-color: ${palette.white};
      &:after {
        background-color: ${({ $color }) => $color};
      }
    }
  }
  &:after {
    content: '${({ $text }) => $text}';
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 58px;
    border: 2px solid ${palette.black};
    background-color: ${palette.white};
    ${fontStyles.titleH3}
    color: ${palette.black};
    padding: 0 10px;
    margin: 10px;
    transition: background-color 0.3s;
  }
`;
