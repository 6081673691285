import React from 'react';

import { HOME_PAGES } from 'utils/routes';

import { Container, Item } from './styles';

const MobileMenu = () => (
  <Container>
    {HOME_PAGES.map(({ to, name, color }) => (
      <Item key={to} to={to} $color={color}>
        {name}
      </Item>
    ))}
  </Container>
);

export default MobileMenu;
