import { handleActions } from 'redux-actions';
import { omit } from 'ramda';

import {
  SET_SEEN_ANIMATION,
  UPDATE_PUBLIC_FILTER,
  UPDATE_CERTIFICATE_KIND,
  UPDATE_TOPOLOGY,
  UPDATE_PUBLIC_POPULATION,
  UPDATE_PUBLIC_ELDERLY,
  UPDATE_PUBLIC_DEMENTIA,
  UPDATE_PUBLIC_DOCTOR_DENSITY,
} from './types';
import { SEEN_ANIMATION, FILTER, CERTIFICATE_KIND, DATA, POPULATION_ID, ELDERLY_ID, DEMENTIA_ID, DOCTOR_ID, TOPOLOGY } from '.';

const init = {
  [SEEN_ANIMATION]: false,
  [FILTER]: null,
  [CERTIFICATE_KIND]: 1040,
  [DATA]: {},
  [TOPOLOGY]: null,
};

export default handleActions(
  {
    [SET_SEEN_ANIMATION]: (state, { payload }) => ({ ...state, [SEEN_ANIMATION]: payload }),
    [UPDATE_PUBLIC_FILTER]: (state, { payload }) => ({
      ...state,
      [FILTER]: payload,
      [DATA]: omit([POPULATION_ID, ELDERLY_ID, DEMENTIA_ID], state[DATA]),
    }),
    [UPDATE_CERTIFICATE_KIND]: (state, { payload }) => ({
      ...state,
      [CERTIFICATE_KIND]: payload,
      [DATA]: omit([DOCTOR_ID], state[DATA]),
    }),
    [UPDATE_PUBLIC_POPULATION]: (state, { payload }) => ({
      ...state,
      [DATA]: { ...state[DATA], [POPULATION_ID]: payload },
    }),
    [UPDATE_PUBLIC_ELDERLY]: (state, { payload }) => ({
      ...state,
      [DATA]: { ...state[DATA], [ELDERLY_ID]: payload },
    }),
    [UPDATE_PUBLIC_DEMENTIA]: (state, { payload }) => ({
      ...state,
      [DATA]: { ...state[DATA], [DEMENTIA_ID]: payload },
    }),
    [UPDATE_PUBLIC_DOCTOR_DENSITY]: (state, { payload }) => ({
      ...state,
      [DATA]: { ...state[DATA], [DOCTOR_ID]: payload },
    }),
    [UPDATE_TOPOLOGY]: (state, { payload }) => ({
      ...state,
      [TOPOLOGY]: payload,
    }),
  },
  init
);
