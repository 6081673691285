import { useMemo, useCallback, useEffect } from 'react';

import { useAsyncState } from '.';

export const useMobileDetect = (size = 991) => {
  const mediaQuery = useMemo(() => window.matchMedia(`(max-width: ${size}px)`), [size]);
  const [isMobile, setMobile] = useAsyncState(mediaQuery.matches);

  const handleChange = useCallback(({ matches }) => setMobile(matches), [setMobile]);

  useEffect(() => {
    if (mediaQuery.addEventListener) {
      mediaQuery.addEventListener('change', handleChange);
    } else {
      mediaQuery.addListener(handleChange);
    }

    return () => {
      if (mediaQuery.removeEventListener) {
        mediaQuery.removeEventListener('change', handleChange);
      } else {
        mediaQuery.removeListener(handleChange);
      }
    };
  }, [handleChange, mediaQuery]);

  return isMobile;
};
