import { createName } from 'store/utils';

import { FEATURE_NAME } from './consts';

const getName = createName(FEATURE_NAME);

export const SET_SEEN_ANIMATION = getName('SET_SEEN_ANIMATION');
export const UPDATE_PUBLIC_FILTER = getName('UPDATE_PUBLIC_FILTER');
export const UPDATE_CERTIFICATE_KIND = getName('UPDATE_CERTIFICATE_KIND');
export const UPDATE_TOPOLOGY = getName('UPDATE_TOPOLOGY');
export const UPDATE_PUBLIC_POPULATION = getName('UPDATE_PUBLIC_POPULATION');
export const UPDATE_PUBLIC_ELDERLY = getName('UPDATE_PUBLIC_ELDERLY');
export const UPDATE_PUBLIC_DEMENTIA = getName('UPDATE_PUBLIC_DEMENTIA');
export const UPDATE_PUBLIC_DOCTOR_DENSITY = getName('UPDATE_PUBLIC_DOCTOR_DENSITY');

export const CREATE_BUG_REPORT = getName('CREATE_BUG_REPORT');
export const SEND_CONTACT = getName('SEND_CONTACT');
export const UPLOAD_MEDIA = getName('UPLOAD_MEDIA');
export const DOWNLOAD_XLSX = getName('DOWNLOAD_XLSX');
export const FETCH_PUBLIC_LOCALITIES = getName('FETCH_PUBLIC_LOCALITIES');
export const FETCH_PUBLIC_POPULATION = getName('FETCH_PUBLIC_POPULATION');
export const FETCH_PUBLIC_ELDERLY = getName('FETCH_PUBLIC_ELDERLY');
export const FETCH_PUBLIC_DEMENTIA = getName('FETCH_PUBLIC_DEMENTIA');
export const FETCH_PUBLIC_DOCTOR_DENSITY = getName('FETCH_PUBLIC_DOCTOR_DENSITY');
